<template lang="pug">
.sidebar-input-wrapper.d-flex.flex-column.align-items-start
  .sidebar-input-wrapper-label
    label {{ $t('endDate') }}
  date-input(:property="property" @change="reInitTimeInput" :invalid="!!errorMessage")
  time-input.mt-2(ref="timeInput" :property="property" :invalid="!!errorMessage")
  transition(name="fade")
    .sidebar-input-wrapper-error.mt-4(v-if="!!errorMessage") {{ $t(errorMessage) }}
</template>
<script>
  import { mapState } from 'vuex';

  export default {
    components: {
      DateInput: () => import('./DateInput.vue'),
      TimeInput: () => import('./TimeInput.vue'),
    },
    props: {
      property: { type: String, required: true },
    },
    computed: {
      ...mapState(['validationError']),
      errorMessage() {
        return this.validationError?.property === this.property ? this.validationError.msg : null;
      },
    },
    methods: {
      reInitTimeInput() {
        if (this.$refs.timeInput) this.$refs.timeInput.reInitFlatpickr();
      },
    },
  };
</script>

<style lang="sass" scoped>
  .sidebar-input-wrapper
    .sidebar-input-wrapper-label
      label
        position: relative
        top: auto
        left: auto
</style>
